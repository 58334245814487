.connectButton {  
    background-color: #6c47ff !important;  
    padding: 0.75rem 1.5rem !important;
    border-color: none !important; 
    border-radius: none !important; 
    border: none;
    font-family: Montserrat !important;
    font-weight: 700 !important;
  }

.mainh1 {  
  display: flex;
  color: white !important;  
  font-family: Montserrat;
  font-size: 28px;
  font-style: italic;
  font-weight: 900;
  justify-content: center;
  align-items: center;
  margin: 15rem 10rem 0rem 10rem;
}

.mainp {  
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 5rem 0rem 5rem;
}
