.invitebutton {  
    color: white;
    border: none;
    background-color: #6c47ff;
    display: inline-block;
    text-align: center;
    border-radius: 0;
    padding: 0.75rem 2rem;
    margin-top: 1rem;
    cursor: pointer;
    text-align: center;
    font-weight: 800;
    font-style: italic;
    font-size: 14px;
    letter-spacing: 1px;
    font-family: 'Montserrat';
  }